import store from '../../store';

export default class WS {

  /**
   * Init WS configuration
   */
  constructor() {
    this._connection = null;
    this._callbacks = {};

    this.openConnection();
  }

  /**
   * Open WS connection
   */
  openConnection() {
    const accessToken = store.getters['getAuth'];

    if (accessToken) {
      const url = `${process.env.VUE_APP_WSS_URL}?Bearer=${accessToken.token}`;
      this.setConnection(new WebSocket(url));
    }
  }

  /**
   *
   * @param connection
   */
  setConnection(connection = null) {
    this._connection = connection;
    if (connection) {
      console.log('WS connection is open');
    }
  }

  /**
   * Get WS connection
   *
   * @returns {null|WebSocket|*}
   */
  getConnection() {
    return this._connection;
  }

  /**
   * Check if WS has connection
   *
   * @returns {boolean}
   */
  hasConnection() {
    return !!this.getConnection();
  }

  /**
   * Close current connection
   */
  closeConnection() {
    if (this.hasConnection()) {
      this.getConnection().close();
      this.setConnection(null);
      console.log('WS connection is closed');
    }
  }

  /**
   * Wait for connection
   */
  waitForConnection(callback, interval) {
    if (!store.getters['getAuth']) return;

    if (this.hasConnection() && this.getConnection().readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  /**
   * Get response event from WS server
   *
   */
  onMessage() {
    this.getConnection().onmessage = event => {
      const data = JSON.parse(atob(event.data));

      if (data.error_id === 0) {
        console.log('WS_onMessage', data);
      } else {
        console.error('WS_onMessage error', data)
      }

      for (const callbackName in this._callbacks) {
        const callback = this._callbacks[callbackName];
        if (callback !== null) {
          callback(JSON.parse(atob(event.data)));
        }
      }
    }
  }

  /**
   * Add callback function
   *
   * @param name
   * @param callback
   */
  addOnMessageCallback(name, callback) {
    this._callbacks[name] = callback;
  }

  /**
   * Update callback function
   *
   * @param name
   * @param callback
   */
  updateOnMessageCallback(name, callback) {
    this._callbacks[name] = callback;
  }

  /**
   * Send data to WS server
   *
   * @param method_id
   * @param data
   */
  async sendMessage(method_id, data) {
    if (!this.hasConnection()) {
      console.error('You need to establish a connection')
      return;
    }

    if (this.getConnection().readyState === 3 ) {
      this.closeConnection();
      this.openConnection();
      this.onMessage();

      // wait until new connection is open
      while (this.getConnection().readyState !== 1) {
        await new Promise(r => setTimeout(r, 250));
      }
    }

    this.getConnection().send(
      btoa(
        JSON.stringify({
          method_id,
          request_id: new Date().getTime(),
          data
        })
      )
    );

    console.log('WS_sendMessage', {method_id, data});
  }


}
